/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup } from 'reactstrap';
import FormGroupInput from '../common/FormGroupInput'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest, copiriAxiosGet, checkValidation } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
import { enc } from '../../gibberish-aes';
import {genPageData} from '../langconfig/langconfig'; // eslint-disable-next-line
const AddEditProfilePhoneModal = props => {
	// eslint-disable-next-line
	const { data, type, inputField, setInputField, pageLanguageContent } = props;
	const [dailCodes, setDailCodes] = useState([]);
	const [validation, setValidation] = useState({
		error_phone: '',
		error_dial: ''
	});

	useEffect(() => {
		if (props.showHide) {
			if (type && (type === 'edit_phone' || type === 'add_phone')) {
				getDailCode();
			}
		}
		// eslint-disable-next-line
	}, [props.showHide]);

	function getDailCode() {
		copiriPortalRequest('/canon/dialcodes', config.app_version, 'get')
			.then(response => {
				if (response.data.length > 0) {
					setDailCodes(response.data);
				}
			})
			.catch(error => {});
	}

	const inputsHandler = e => {
		setInputField({ ...inputField, [e.target.name]: e.target.value });
	};

	function updatePhoneData() {
		let errors = { ...validation };
		var errorCount = 0;

		if (!inputField.phone.trim()) {
			errors.error_phone = pageLanguageContent['PRL026'];
			errorCount++;
		} else if (!checkValidation(inputField.phone, 'phone')) {
			errors.error_phone = pageLanguageContent['PRL142'];
			errorCount++;
		} else {
			errors.error_phone = '';
		}

		if (!inputField.dial.trim()) {
			errors.error_dial = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_dial = '';
		}
		setValidation(errors);
		if (errorCount <= 0) {
			var sendFormData = {};
			sendFormData['phone_num'] = inputField['phone'];
			var country_code = '+1';
			sendFormData['phone_code_id'] = country_code;
			for (var i = 0; i < dailCodes.length; i++) {
				if (dailCodes[i]['id'] === parseInt(inputField['dial'])) {
					country_code = dailCodes[i]['dialcode'];
				}
			}
			if (country_code && country_code !== '') {
				sendFormData['phone_code_id'] = '+' + country_code;
			}

			copiriPortalRequest('/users/' + data['id'], config.app_version, 'patch', sendFormData)
				.then(response => {
					if (response.data.status && response.data.status === 'SUCCESS') {
						toast.success('Success');
						props.onClick();
						props.updateModal(type, data['id']);
					}
				})
				.catch(error => {
					toast.error(error.response.data.result);
				});
		}
	}

	return (
		<>
			<Modal isOpen={props.showHide} toggle={props.onClick} className='email_modal'>
				<ModalHeader
					toggle={props.onClick}
					style={{ backgroundColor: '#337ab7', border: '#337ab7' }}
					className='modalHeader'
				>
					{type && type === 'edit'
						? pageLanguageContent['PRL114']
							? pageLanguageContent['PRL114']
							: ''
						: pageLanguageContent['PRL113']
						? pageLanguageContent['PRL113']
						: ''}
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col lg={4}>
							<FormGroup>
								<Label for='dial'>{pageLanguageContent['PRL115'] ? pageLanguageContent['PRL115'] : ''}</Label>
								<select
									id='dial'
									label={pageLanguageContent['PRL116'] ? pageLanguageContent['PRL116'] : ''}
									type='text'
									onChange={inputsHandler}
									value={inputField.dial}
									name='dial'
									className='form-control'
								>
									{dailCodes.length > 0 &&
										dailCodes.map((item, i) => (
											<option value={item.id} key={i}>
												{item.name}
											</option>
										))}
								</select>
							</FormGroup>
							{validation.error_dial && <p className='formValidationError'>{validation.error_dial}</p>}
						</Col>

						<Col lg={8}>
							<FormGroupInput
								id='phone'
								label={pageLanguageContent['PRL117'] ? pageLanguageContent['PRL117'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.phone}
								name='phone'
							/>
							{validation.error_phone && <p className='formValidationError'>{validation.error_phone}</p>}
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color='primary' onClick={updatePhoneData}>
						{genPageData['GENSAV'] ? genPageData['GENSAV'] : ''}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
export default AddEditProfilePhoneModal;
