/* eslint react/no-multi-comp: 0, react/prop-types: 0 */
// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
// eslint-disable-next-line
import { Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup } from 'reactstrap';
import FormGroupInput from '../common/FormGroupInput'; // eslint-disable-next-line
import { copiriAxiosPost, copiriPortalRequest, copiriAxiosGet } from '../api/api'; // eslint-disable-next-line
import config from '../config/config'; // eslint-disable-next-line
import { toast } from 'react-toastify'; // eslint-disable-next-line
import {genPageData} from '../langconfig/langconfig'; // eslint-disable-next-line
const AddEditProfileAddressModal = props => {
	// eslint-disable-next-line
	const { data, countriesData, inputField, setInputField, type, pageLanguageContent } = props;
	const [stateData, setStateData] = useState([]); // eslint-disable-next-line
	const [validation, setValidation] = useState({
		error_address_1: '',
		error_city: '',
		error_state: '',
		error_postal_code: ''
	});

	const [checkChangeData, setCheckChangeData] = useState({
		street_address_1: false,
		street_address_2: false,
		street_address_3: false,
		city: false,
		state: false,
		postal_code: false,
		facility_name: false,
		country: false
	});
	useEffect(() => {
		if (props.showHide) {
			setValidation([]);
			getStatesData(235);
		}
	}, [props.showHide]);

	const inputsHandler = e => {
		setInputField({ ...inputField, [e.target.name]: e.target.value });
		setCheckChangeData({ ...checkChangeData, [e.target.name]: true });
	};

	/* function to get the states Data based on country id */
	function getStatesData(country_id = 235, state_id) {
		var url = config.portal_url + '/?content_request=states&cid=' + country_id;
		copiriAxiosGet(url)
			.then(response => {
				setStateData(response.data);
			})
			.catch(error => {});
	}

	function updateAddressData() {
		let errors = { ...validation };
		var errorCount = 0;

		if (!inputField.street_address_1.trim()) {
			errors.error_address_1 = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_address_1 = '';
		}

		if (!inputField.city.trim()) {
			errors.error_city = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_city = '';
		}

		if (!inputField.state.trim()) {
			errors.error_state = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_state = '';
		}

		if (!inputField.postal_code.trim()) {
			errors.error_postal_code = pageLanguageContent['PRL026'];
			errorCount++;
		} else {
			errors.error_postal_code = '';
		}
		setValidation(errors);
		var sendFormData = {};

		if (checkChangeData['street_address_1']) {
			sendFormData['address_1'] = inputField['street_address_1'];
		}
		if (checkChangeData['street_address_2']) {
			sendFormData['address_2'] = inputField['street_address_2'];
		}
		if (checkChangeData['street_address_3']) {
			sendFormData['address_3'] = inputField['street_address_3'];
		}
		if (checkChangeData['city']) {
			sendFormData['city'] = inputField['city'];
		}
		if (checkChangeData['state']) {
			sendFormData['state_id'] = inputField['state'];
		}
		if (checkChangeData['postal_code']) {
			sendFormData['post_code'] = inputField['postal_code'];
		}
		if (checkChangeData['facility_name']) {
			sendFormData['facility'] = inputField['facility_name'];
		}
		sendFormData['country_id'] = inputField['country'];

		if (errorCount <= 0) {
			copiriPortalRequest('/users/' + data['id'], config.app_version, 'patch', sendFormData)
				.then(response => {
					if (response.data.status && response.data.status === 'SUCCESS') {
						toast.success('Success');
						props.onClick();
						props.updateModal(type, data['id']);
					}
				})
				.catch(error => {});
		}
	}

	return (
		<>
			<Modal isOpen={props.showHide} toggle={props.onClick}>
				<ModalHeader
					toggle={props.onClick}
					style={{ backgroundColor: '#337ab7', border: '#337ab7' }}
					className='modalHeader'
				>
					{pageLanguageContent['PRL091'] ? pageLanguageContent['PRL091'] : ''}
				</ModalHeader>
				<ModalBody>
					<Row>
						<Col lg={12}>
							<FormGroupInput
								id='facility_name'
								label={pageLanguageContent['PRL092'] ? pageLanguageContent['PRL092'] : ''}
								name='facility_name'
								type='text'
								onChange={inputsHandler}
								value={inputField.facility_name}
							/>
						</Col>

						<Col lg={12}>
							<FormGroupInput
								id='street_address_1'
								label={pageLanguageContent['PRL093'] ? pageLanguageContent['PRL093'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.street_address_1}
								name='street_address_1'
							/>
							{validation.error_address_1 && <p className='formValidationError'>{validation.error_address_1}</p>}
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='street_address_2'
								label={pageLanguageContent['PRL094'] ? pageLanguageContent['PRL094'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.street_address_2}
								name='street_address_2'
							/>
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='street_address_3'
								label={pageLanguageContent['PRL095'] ? pageLanguageContent['PRL095'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.street_address_3}
								name='street_address_3'
							/>
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='city'
								label={pageLanguageContent['PRL096'] ? pageLanguageContent['PRL096'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.city}
								name='city'
							/>
							{validation.error_city && <p className='formValidationError'>{validation.error_city}</p>}
						</Col>

						<Col lg={6}>
							<FormGroupInput
								id='postal_code'
								label={pageLanguageContent['PRL097'] ? pageLanguageContent['PRL097'] : ''}
								type='text'
								onChange={inputsHandler}
								value={inputField.postal_code}
								name='postal_code'
							/>
							{validation.error_postal_code && <p className='formValidationError'>{validation.error_postal_code}</p>}
						</Col>

						<Col lg={6}>
							<FormGroup>
								<Label for='lodgeCountry'>Country</Label>
								<select
									id='country'
									label={pageLanguageContent['PRL098'] ? pageLanguageContent['PRL098'] : ''}
									type='text'
									onChange={inputsHandler}
									value={inputField.country}
									name='country'
									className='form-control'
								>
									<option value=''>{pageLanguageContent['PRL100'] ? pageLanguageContent['PRL100'] : ''}</option>
									{countriesData.length > 0 &&
										countriesData.map((item, i) => (
											<option value={item.id} key={item.id}>
												{item.name}
											</option>
										))}
								</select>
							</FormGroup>
						</Col>

						<Col lg={6}>
							<FormGroup>
								<Label for='lodgeCountry'>{pageLanguageContent['PRL099'] ? pageLanguageContent['PRL099'] : ''}</Label>
								<select
									id='state'
									label={pageLanguageContent['PRL099'] ? pageLanguageContent['PRL099'] : ''}
									type='text'
									onChange={inputsHandler}
									value={inputField.state}
									name='state'
									className='form-control'
								>
									<option value=''>{pageLanguageContent['PRL100'] ? pageLanguageContent['PRL100'] : ''}</option>
									{stateData.length > 0 &&
										stateData.map((item, i) => (
											<option value={item.id} key={item.id}>
												{item.name}
											</option>
										))}
								</select>
							</FormGroup>
							{validation.error_state && <p className='formValidationError'>{validation.error_state}</p>}
						</Col>
					</Row>
				</ModalBody>
				<ModalFooter>
					<Button color='primary' onClick={updateAddressData}>
						{genPageData['GENSAV'] ? genPageData['GENSAV'] : ''}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
export default AddEditProfileAddressModal;
